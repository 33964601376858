import { datadogRumWrapper } from 'src/utils/datadog/wrappers/datadog-rum-wrapper'
import { ENV } from 'src/utils/env'
import { getWindowNextDataProps } from 'src/utils'
import { getNativeAppVersion } from 'src/components/utils/use-native-app-version'

export const notAllowedBushelOpsSubdomainsRegex =
  /https:\/\/(?!(id)|(configcat)\.)[a-z0-9-]+(\.[a-z0-9-]+)*\.bushelops\.com/

export async function initDatadogRUM() {
  if (!ENV.APP_VERSION) {
    console.log('[datadog rum] monitoring is disabled (app version unknown)')
    return
  }
  if (!ENV.BUSHEL_ENVIRONMENT) {
    console.log('[datadog rum] monitoring is disabled (environment unknown)')
    return
  }
  if (!ENV.DATADOG_RUM_APP_ID) {
    console.log('[datadog rum] monitoring is disabled (app id unknown)')
    return
  }
  if (!ENV.DATADOG_RUM_CLIENT_TOKEN) {
    console.log('[datadog rum] monitoring is disabled (client token unknown)')
    return
  }
  if (!ENV.DATADOG_RUM_SERVICE) {
    console.log('[datadog rum] monitoring is disabled (service id unknown)')
    return
  }

  datadogRumWrapper.init({
    applicationId: ENV.DATADOG_RUM_APP_ID,
    clientToken: ENV.DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: ENV.DATADOG_RUM_SERVICE,
    env: ENV.BUSHEL_ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: ENV.APP_VERSION,
    sessionSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    silentMultipleInit: true,
    startSessionReplayRecordingManually: true,
    /**
     * Trace on all the 3 major API domains
     * Due to restrictive CORS headers, we cannot trace to Keycloak, or ConfigCat
     */
    allowedTracingUrls: [
      // Allow tracing for bushelops.com, except for subdomains starting with 'id' or 'configcat'
      notAllowedBushelOpsSubdomainsRegex,

      // xyz.bushelpowered.com, except 'id.xyz' subdomains
      /https:\/\/(?!id\.)[a-z0-9-]+\.bushelpowered\.com/,

      // xyz.scaleticket.net, except 'id.xyz' subdomains
      /https:\/\/(?!id\.)[a-z0-9-]+\.scaleticket\.net/,
    ],
  })

  setDatadogUser(getWindowNextDataProps())
  await addNativeAppVersionTagIfNeeded()
}

export function setDatadogUser(userInfo: { slug?: string; user?: Me }) {
  const { slug, user } = userInfo

  if (user) {
    const id = user.id?.toString()
    const name = `${user.first_name ?? ''} ${user.last_name ?? ''}`.trim()
    const phone = user.phone_numbers?.data?.find((phoneObj) => phoneObj.primary)?.phone
    const allPhones = user.phone_numbers?.data
      ?.map((phoneObj) => `${phoneObj.id}: ${phoneObj.phone}`)
      .join(', ')
    const email = user.email_addresses?.data?.find((emailObj) => emailObj.primary)?.email || null

    datadogRumWrapper.setUser({
      ...(id && { id }),
      ...(name && { name }),
      ...(phone && { phone }),
      ...(allPhones && { allPhones }),
      ...(email && { email }),
      ...(slug && { slug }),
    })
  }
}

export function removeDatadogUser() {
  datadogRumWrapper.removeUser()
}

async function addNativeAppVersionTagIfNeeded() {
  const nativeAppVersion = await getNativeAppVersion()

  if (nativeAppVersion) {
    datadogRumWrapper.addRumGlobalContext('native_app_version', nativeAppVersion)
  }
}
