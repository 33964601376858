import { createContext, useContext } from 'react'
import { AppConfig } from 'src/api/centre-service/data-models/app-config'
import { useGlobalFlagr } from 'src/utils/flagr'

export interface NavConfig {
  pageTitle?: string
  activeItem?: string
}

export interface AppConfigContextValue {
  cookies: { [key: string]: string }
  config: AppConfig
  setConfig: (c: AppConfig) => void
  pageTitle: string
  activeItem: string
  setNavData: (d: NavConfig) => void
}

export const AppConfigContext = createContext<AppConfigContextValue>(null)

export const POSSIBLE_FEATURES = [
  'cash_bids',
  'checks',
  'commodity_balances',
  'bin_samples',
  'coop_investments',
  'contracts',
  'delivery_tickets',
  'esign',
  'futures',
  'locations_and_hours',
  'settlements',
  'tickets',
  'outbound_tickets',
  'transactions',
  'payments',
  'payments_payable',
  'payments_receivable',
  'payments_direct_deposit',
  'prepaid_contracts',
  'statements',
  'work_orders',
]

export const useConfig = () => {
  const ctx = useContext(AppConfigContext)
  const { isFeatureFlagEnabled } = useGlobalFlagr()
  const { cookies, config, setConfig, pageTitle, activeItem, setNavData } = ctx ?? {}
  const allFeaturesDisabled = !Object.entries(config?.features ?? {})
    .filter(([key]) => POSSIBLE_FEATURES.includes(key))
    .some(([_key, feature]: [string, { version: string | boolean }]) => !!feature.version)

  return {
    cookies,
    config,
    setConfig,
    pageTitle,
    activeItem,
    setNavData,
    isFeatureEnabled: (key: string, extraEnabledCheck?: (config: AppConfig) => boolean) => {
      const configEnabled = !!config?.features?.[key]?.version
      const flagEnabled = isFeatureFlagEnabled(key)
      const extraEnabled =
        typeof extraEnabledCheck === 'function' ? extraEnabledCheck(config) : true

      return configEnabled && flagEnabled && extraEnabled
    },
    allFeaturesDisabled,
    getConfigValue: (key: string, defaultValue = null) => config?.config?.[key] || defaultValue,

    tickets: {
      listRemarks: () => config.config.ticket_list_remarks,
      listAccountIdMapping: () => config.config.ticket_list_account_id_mapping,
      hideAccountIdOnTicketDetail: () => config.config.hide_account_identifier_on_ticket_detail,
      ignoreWeighingTimezone: () => config.config.ticket_ignore_timezone,
      hideTime: () => config.config.ticket_hide_created_time,
      shouldFetchTicketApplications: () => !!config.features?.ticket_applications?.version,
    },

    contracts: {
      listAccountIdMapping: () => config.config.contract_account_id_mapping,
      isPriceBasisVisible: () => !!config.config.display_options?.contracts?.price_basis?.visible,
      locationHeaderProperty: () => {
        return config.config.contract_header_location === 'delivery'
          ? 'delivery_location_name'
          : 'contract_location_name'
      },
      contractHeaderLocation: () => config.config.contract_header_location,
      showContractedLocation: () => config.config.contract_contracted_location_detail,
      showDeliveryLocation: () => config.config.contract_delivery_location_detail,
      showCurrency: () => config.config.show_currency_on_contracts,
      contractsVersion: () => config.features.contracts?.version,
      contractsUpScale: () => config.config.contracts_up_scale,
      shouldFetchTicketApplications: () => !!config.features?.ticket_applications?.version,
    },

    settlements: {
      listAccountIdMapping: () => config.config.settlement_account_id_mapping,
      shouldFetchTicketApplications: () => !!config.features?.ticket_applications?.version,
    },

    cashBids: {
      cashBidsVersion: () => config.features.cash_bids?.version,
      cashBidsRequireLogin: () => config.config.cash_bids_require_login,
      cashBidsLargeText: () => config.config.cash_bids_nearest_month_large_text,
      disclaimerConfigs: () => ({
        disclaimerImageLink: config.config.cash_bids_disclaimer_image_link,
        disclaimerImageLink2: config.config.cash_bids_disclaimer_image_link2,
        disclaimerImageUrl: config.config.cash_bids_disclaimer_image_url,
        disclaimerImageUrl2: config.config.cash_bids_disclaimer_image_url2,
        disclaimerLink1: config.config.cash_bids_disclaimer_link_one,
        disclaimerLink2: config.config.cash_bids_disclaimer_link_two,
        disclaimer: config.config.cash_bids_disclaimer,
      }),
    },
    customMenuLinks: () => config?.config?.custom_menu_links,
  }
}
