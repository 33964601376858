import { aggregatorClient } from 'src/api/aggregator/client'

export const locationFavoritesBaseUrl = 'api/aggregator/location/favorites'

export const locationFavoritesRequests = {
  getLocationFavorites: () =>
    aggregatorClient
      .post(`${locationFavoritesBaseUrl}/v1/GetLocationFavorites`)
      .json<GetLocationsFavoriteResponse>(),
  addLocationFavorites: (body: AddLocationsFavoriteRequest) =>
    aggregatorClient
      .post(`${locationFavoritesBaseUrl}/v1/AddLocationFavorites`, { json: body })
      .json<AddLocationsFavoriteResponse>(),
  removeLocationFavorites: (body: RemoveLocationsFavoriteRequest) =>
    aggregatorClient
      .post(`${locationFavoritesBaseUrl}/v1/RemoveLocationFavorites`, { json: body })
      .json<RemoveLocationsFavoriteResponse>(),
}
